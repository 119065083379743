.main-content {
    text-align: center;
    background-color: #000000;
}
  
.main-image {
    width: 100%;
    height: auto;
}
  
.main-text {
    margin: 16px;
    font-size: 25px;
    font-family: 'Montserrat Variable';
    color: #ffffff;
}

.main-guides {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin: auto; 
}

.thumbnail-wrapper {
    margin: 10px 20px; 
    width: 100%;
    display: flex;
    align-items: center; /* vertically center items */
    border-radius: 10px;
    overflow: hidden; 
}

.thumbnail-wrapper img {
    width: 500px;
    height: auto;
}
  
.link-text {
    margin-left: 12px;
    margin-right: 12px;
    font-family: 'Roboto Condensed';
    color: #8B0000;
    font-size: 1.5em;
}
  
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* This will create 3 columns. Adjust as needed. */
    gap: 16px;
}
  
.image-grid img {
    width: 600px;
    height: auto;
    border-radius: 8px;
}  

.price-text {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 8px;
  }


/* Change based on screen size */
@media only screen and (max-width: 768px) {
    .main-text {
        font-size: 16px;
    }

    .main-guides {
        width: 350px;
        margin: auto;
    }

    .thumbnail-wrapper img {
        width: 300px;
    }

    .link-text {
        margin-left: 12px;
        font-family: 'Roboto Condensed';
        color: #8B0000;
        font-size: 1em;
    }
}