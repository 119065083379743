.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #060644;
    color: #ffffff;
    padding: 1rem;
}
  
.social-icons a {
    margin: 0 1rem;
    color: #ffffff;
}
  
.copyright {
    text-align: center;
    color: #ffffff;
}
  
/* Mobile adjustments */
@media only screen and (max-width: 768px) {
  .footer-container {
      flex-direction: column;
  }
  
  .social-icons {
      margin-bottom: 1rem;
  }
}
  