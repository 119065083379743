#contact {
  margin: 20px;
  padding: 20px;
}

label {
  display: block;
  margin-top: 10px;
  /* margin: 16px; */
  /* font-size: 25px; */
  font-family: 'Montserrat Variable';
  color: #ffffff;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
}

.contact-us button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}