/* Header Styles */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
}

.header-container h1 {
    font-family: 'LEAGUE GOTHIC';
    font-size: 3rem;
    color: #000000;
    margin: 0;
    padding: 0;
}

.hamburger-menu {
    display: none;
    margin-right: 15px;
}

.desktop-menu {
    display: none;
    /* Initially hidden */
    gap: 10px;
}

.side-menu {
    display: flex;
    flex-direction: column;
    /* Stack the buttons vertically */
    justify-content: space-between;
    align-items: center;
    /* Align items horizontally in the center */
    position: fixed;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    /* or whatever width you want */
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    transform: translateX(-100%);
}

.side-menu.open {
    transform: translateX(0%);
}

.side-menu button {
    /* margin: 10px;
    padding: 10px; */
}

.login-button {
    background-color: #007bff;
}

.top-buttons,
.bottom-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    height: 44px;
    padding: 1.5rem 0.75rem;
}

.top-buttons button,
.bottom-buttons button {
    margin: 10px;
}

.my-button-login {
    margin: 0;
    padding: 10;
    padding: 10px;
    width: 100%;
    background-color: #233DFF;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition-duration: 0.4s;
}

.my-button {
    /* margin-top: 10px; */
    padding: 10px;
    width: 100%;
    background-color: #727880;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition-duration: 0.4s;
}

.my-button:hover {
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;
}

.my-button-login:hover {
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;
}

/* Show hamburger menu and hide other elements at small screen sizes */
@media only screen and (max-width: 768px) {
    .hamburger-menu {
        display: block;
    }

    .header-container {
        justify-content: left;
    }

    .header-container h1 {
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .header-container h1 {
        font-size: 3rem;
    }

    .desktop-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .my-button {
        margin-top: 10px;
    }

    .hamburger-menu {
        display: none;
        /* Hide on desktop */
    }
}