/* General Styles */
body {
    font-family: 'Montserrat';
    margin: 0;
    padding: 0;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #000000;
}

.content {
    flex: 1;
}

/* Footer Styles */
.footer-container {
    background-color: #363D46;
    color: #727880;
    padding: 10px;
}

/* Main Content Styles */
.main-container {
    background-color: #000000;
    /* color: #727880; */
    padding: 20px;
}

/* .links {
    display: flex;
    gap: 10px;
} */

a {
    color: #727880;
    text-decoration: none;
}