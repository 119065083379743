.about-section {
    background-color: #000000;
    color: #ffffff;
}

.about-content-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 60px;
}

.about-content {
    overflow: auto;
}

.about-text {
    font-family: 'Montserrat Variable';
    color: #ffffff;
    font-size: 1.25em;
}

.about-image {
    float: left;
    /* or 'right' if you want the image on the right */
    /* max-width: 300px; */
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;  /* Square image */
    height: 300px;  /* Square image */
    object-fit: cover; 
}

@media (max-width: 768px) {
    .about-content-container {
        max-width: 740;
        padding: 0 35px;
    }
    
    .about-image {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 150px;
        height: 150px;
    }

    .about-text {
        text-align: left;
        font-size: 0.95em;
    }
}