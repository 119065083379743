/* Montserrat Regular */
@font-face {
    font-family: 'Montserrat Variable';
    src: url('./fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}
  
/* Roboto Condensed Regular */
@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
  
/* Roboto Condensed Bold */
@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
  
/* LEAGUE GOTHIC */
@font-face {
    font-family: 'LEAGUE GOTHIC';
    src: url('./fonts/LeagueGothic-Regular-VariableFont_wdth.ttf') format('truetype');
}